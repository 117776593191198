<template>
    <main-page>
        <div class="header">
            <ul class="locations">
                <li>搭配管理</li>
                <li>搭配详情</li>
            </ul>
            <div v-if="outfit">
                <template v-if="outfit.status === 1 && outfit.can_search === 1">
                    <a class="edit-btn">该搭配正在平台推荐中，暂不能编辑或删除！</a>
                </template>
                <template v-else>
                    <a class="edit-btn" @click="showDetailModal = true">修改搭配信息 <span
                            class="iconfont pc-youjiantou"></span></a>
                </template>
            </div>
        </div>
        <div v-if="loading" class="loading-container">
            <img src="/assets/images/loading.gif"/>
        </div>
        <div v-else-if="outfit === null" class="loading-container">
            <div class="text-center">
                没有找到对应的搭配
                <div>
                    <button class="btn" @click="gotoList">返回搭配管理</button>
                </div>
            </div>
        </div>
        <div v-else class="detail-body">

            <div class="left" id="detail-left">

                <affix relative-element-selector="#detail-left" :offset="{top:80,bottom:40}">
                    <div class="outfit-img">
                        <button @click="editOutfit" class="outfit-edit-btn"><span class="iconfont pc-bianji"></span>
                        </button>
                        <img :src="outfit.thumb" alt="">
                    </div>

                    <template v-if="outfit.status === 2 && outfit.reviews ">

                        <div class="reject-text">
                            <span class="iconfont pc-jinggao"></span>
                            <div>
                                {{ outfit.reviews[0].reason }}
                            </div>
                        </div>

                    </template>
                </affix>

            </div>
            <div class="right">

                <div class="title">{{ outfit.title }}</div>

                <div class="prices-commission">
                    <div class="prices">

                        <div class="s-price">
                            <span class="unit">￥</span>
                            <span>{{ outfit.price }}</span>
                        </div>

                        <div class="commission">
                            搭赚 <span><span>￥</span>{{ outfit.commission }}</span>
                        </div>

                    </div>
                    <div class="collect">
                        <span class="iconfont pc-weishoucang"></span> {{ outfit.collect_num }}
                    </div>
                </div>

                <div class="summary">{{ outfit.summary }}</div>

                <div class="goods-list">

                    <template v-for="(goods,k) in outfit.goods">

                        <div :key="k" class="goods-block" :class="{'third' : (k + 1)%3 === 0}">

                            <goods-item :goods="goods"/>

                        </div>

                    </template>

                </div>
                <div class="tags">

                    <template v-for="(tag,k) in tags">
                        <div :key="k" v-if="outfit[tag.key]" class="tag-item">
                            <div class="item-title">{{ tag.name }}</div>

                            <div>
                                <template v-for="(item,ik) in outfit[tag.key] ">

                                    <button :key="ik" class="btn item-btn">{{ item.name }}</button>

                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <drawer v-model="showDetailModal">
                <template v-if="outfit">
                    <deck-content title-image="/assets/images/title.xg.png" :outfit="outfit"
                                  @submitContent="detailUpdate"/>
                </template>
            </drawer>

        </div>
    </main-page>
</template>

<script>
    import MainPage from "@/layout/MainPage";
    import api from "@/repo/api";
    import GoodsItem from "@/components/GoodsItem";
    import {Affix} from 'vue-affix';
    import Drawer from "@/components/Drawer";
    import DeckContent from "../Deck/DeckContent";

    export default {
        name: "Detail",
        components: {DeckContent, Drawer, GoodsItem, MainPage, Affix},
        data() {
            return {
                loading: true,
                outfit: null,
                showDetailModal: false,
                tags: [
                    {
                        name: '适宜着装场合',
                        key: 'occasion'
                    },
                    {
                        name: '搭配风格氛围',
                        key: 'atmosphere',
                    },
                    {
                        name: '季节',
                        key: 'season',
                    },
                    {
                        name: '话题',
                        key: 'topics'
                    },
                    {
                        name: '适宜风格',
                        key: 'styles'
                    }
                ]
            }
        },
        mounted() {
            this.getOutfit()
        },
        methods: {
            editOutfit() {
                this.$router.push(`/dps/deck?outfit_id=${this.outfit.id}`)
            },
            detailUpdate(data) {

                console.log('detailUpdate');

                this.showDetailModal = false

                this.$swal.fire({
                    title: '提交中，请稍候...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                })

                data['id'] = this.outfit.id

                api.createOutfit(data, (result) => {

                    this.$swal.close();

                    if (result.code !== 0) {
                        this.$swal({
                            title: result.msg,
                            icon: 'error',
                            confirmButtonText: '好'
                        });

                        return
                    }

                    this.$swal.fire({
                        title: '修改成功',
                        icon: 'success',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                    })

                    setTimeout(() => {
                        location.reload()
                    }, 3000)

                })
            },
            getOutfit() {
                let id = this.$route.params.id

                api.getOutfitDetail(id, (data) => {
                    this.loading = false
                    if (data.code !== 0) return

                    this.outfit = data.data

                })
            },
            gotoList() {
                this.$router.push('/outfits')
            }
        }
    }
</script>

<style lang="less" scoped>
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-bottom: 15px;
        margin-bottom: 40px;

        border-bottom: 1px solid #EEEEEE;

        li {
            float: left;

            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            padding-right: 15px;
            margin-right: 8px;
            position: relative;
            list-style: none;

            &:after {
                content: '/';
                position: absolute;
                right: 0;
                bottom: 0;
            }

            &:last-child {
                color: #333333;

                &:after {
                    display: none;
                }
            }
        }
    }

    .edit-btn {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 20px;

        .iconfont {
            font-size: 12px;
        }
    }

    .loading-container {

        font-size: 14px;
        line-height: 24px;
        color: #333333;
        padding-top: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 48px;
            height: 48px;
        }

        .btn {
            margin-top: 16px;
        }
    }

    .detail-body {
        display: flex;
        justify-content: left;

        .left {
            width: 600px;
        }

        .right {
            flex: 1;
            padding-left: 48px;
        }
    }

    .outfit-img {
        width: 600px;
        height: 600px;

        position: relative;
        border: 1px solid #F5F5F5;
        background-color: #F5F5F5;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 590px;
            height: 590px;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
        margin-bottom: 15px;
    }

    .goods-block {
        cursor: pointer;
        height: 260px;
        width: 200px;
        margin-bottom: 36px;
        float: left;
        margin-right: 30px;

        &.third {
            margin-right: 0;
        }
    }

    .goods-list {
        overflow: hidden;
    }

    .summary {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 24px;
        margin-bottom: 40px;
    }

    .tag-item {
        margin-bottom: 8px;
        overflow: hidden;
    }

    .item-title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 24px;
        margin-bottom: 15px;
    }

    .item-btn {
        background: #F5F5F5;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        border: none;
        cursor: initial;
        height: 32px;
        margin-right: 15px;
        margin-bottom: 15px;
        float: left;
    }

    .prices-commission {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .prices {
        display: flex;
        align-items: center;
    }

    .s-price {
        display: flex;
        font-size: 24px;
        align-items: baseline;
        margin-right: 32px;

        span {
            color: rgba(227, 29, 26, 0.8);
        }

        .unit {
            font-size: 14px;
            color: rgba(227, 29, 26, 0.8);
        }
    }

    .commission {
        font-size: 14px;
        background: #FFEECB;
        line-height: 24px;
        font-weight: 400;
        color: #FFAD00;
        padding: 0 8px;
        display: inline-block;
        height: 24px;

        span {
            font-size: 14px;
            color: #FFAD00;

            span {
                font-size: 12px;
                color: #FFAD00;
            }
        }
    }

    .collect {
        .iconfont {
            font-size: 12px;
        }

        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
    }

    .tags {
        margin-bottom: 32px;
    }

    .reject-text {
        display: flex;
        justify-content: left;
        align-items: baseline;
        margin-top: 24px;

        .iconfont {
            margin-right: 10px;
            font-size: 14px;
        }

        div {
            font-size: 14px;
            font-weight: 400;
            color: #FFAD00;
            line-height: 24px;
        }
    }

    .outfit-edit-btn {
        position: absolute;
        width: 32px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 50%;
        top: 16px;
        right: 16px;
        cursor: pointer;

        .iconfont {
            font-size: 16px;
        }
    }
</style>