<template>
    <div class="drawer" :class="{'show':show}">
        <div class="drawer-screen" @click="close" :class="screenAnimate"></div>
        <div class="drawer-content" :style="{'width':width ? width : '520px'} " :class="contentAnimate">
            <button class="close-btn iconfont pc-guanbi" @click="close"></button>
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Drawer",
        model: {
            prop: 'active',
            event: 'change',
        },
        props: {
            active: Boolean,
            width: String
        },
        watch: {
            active() {
                if (this.active === this.show) return
                this.active ? this.open() : this.close()
            }
        },
        mounted() {
            this.show = this.active
            if (this.show) this.open()
        },
        data() {
            return {
                screenAnimate: 'fadeIn',
                contentAnimate: 'fadeInRight',
                show: false,
            }
        },
        methods: {
            open() {
                this.screenAnimate = 'fadeIn'
                this.contentAnimate = 'fadeInRightBig'
                this.show = true
            },
            close() {
                this.screenAnimate = 'fadeOut'
                this.contentAnimate = 'fadeOutRight'

                setTimeout(() => {

                    this.show = false
                    this.$emit('change', false)

                }, 1200)
            }
        }
    }
</script>

<style lang="less" scoped>
    .drawer {
        display: none;

        &.show {
            display: block;
            position: relative;
            z-index: 9999;
        }
    }

    .drawer-content, .drawer-screen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .drawer-screen {
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .drawer-content {
        width: 520px;
        background-color: white;
    }

    .close-btn {
        background-color: transparent;
        border: none;
        font-size: 15px;
        position: absolute;
        right: 32px;
        top: 25px;
    }


</style>